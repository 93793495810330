import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
    const navigate = useNavigate(); // Move the useNavigate hook inside the component

    const onLoginSuccess = (credentialResponse) => {
        const user_data = jwtDecode(credentialResponse.credential)
        fetch('https://proto-api.pudam.me/auth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user_data)
        })
        .then(response => response.json())
        .then(data => {
            // Handle the response data here
            console.log(data)
            console.log(data)
            console.log(data)
            if (data.status === 200) {
                sessionStorage.setItem('jwt', data.token);
                console.log('session')
                console.log(sessionStorage.getItem('jwt'));
                navigate('/main', { replace: true });
                console.log('Login Success');
            }
        })
        .catch(error => {
            console.log(`Error:  ${error}`);
        });
    }
    return (
            <GoogleLogin
            fullWidth={true}
            style={{width:'100% important'}}

            onSuccess={(credentialResponse) => onLoginSuccess(credentialResponse)
            }
            login_uri={'http://proto.pudam.me'}
            hosted_domain={'madup.com'}
            onError={() => {
                console.log('Login Failed');
            }}
            />
    );
};



export default LoginForm;