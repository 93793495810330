import './App.css';
import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel,
  Radio, TextField, Slider, Button, Box, Alert, ButtonGroup, Card, Stack, Snackbar,
  ImageList,
  ImageListItem,
  FormGroup,
  Checkbox,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ReactMarkdown from 'react-markdown';
import { CircularProgress, Backdrop } from '@mui/material';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import RadioBtn from './RadioBtn';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Main from './Copywriter'; // Import the Main component
import Index from './Index'; // Import the Index component
import { GoogleOAuthProvider } from '@react-oauth/google';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const App = () => {

  return (
    <SnackbarProvider maxSnack={10}>
      <Router>
        <Routes>
          <Route exact path="/main" element={<Main />} />
          <Route exact path="/" element={<Index />} />
          {/* Add more routes here */}
        </Routes>
      </Router>
    </SnackbarProvider>
  );
}


export default App