import './App.css';
import React, { useState, useEffect } from 'react';
import { Grid, Container, FormControl, FormLabel, RadioGroup, FormControlLabel,
  Radio, TextField, Slider, Button, Box, Alert, ButtonGroup, Card, Stack, Snackbar,
  ImageList,
  ImageListItem,
  FormGroup,
  Checkbox,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import ReactMarkdown from 'react-markdown';
import { CircularProgress, Backdrop } from '@mui/material';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import RadioBtn from './RadioBtn';
import Index from './Index';
import usePagination from '@mui/material/usePagination';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import Pagination from '@mui/material/Pagination';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUp';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDown';
import Header from './Header';
import axios from 'axios';



const modelMapper =  {
  'GPT-4o': 'gpt-4o',
  'ClovaAI': 'clovaai-hcx-003',
  'Gemini-1.5-Pro': 'gemini-1.5-pro-001',
  'Gemini-1.5-Flash': 'gemini-1.5-flash-001'
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  '& img': {
    width: '100%',
    height: 'auto',
    transition: theme.transitions.create(['transform', 'box-shadow']),
  },
  '&:hover img': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[4],
  },
}));

const CustomTabPanel = ({ children, value, index }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}


function Copywriter() {
  const navigate = useNavigate();

  const [COPY_TYPES, setCopyTypes] = useState([]);
  const [TEXT_INPUT_PREFIX, setTextInputPrefix] = useState([]);
  const [PRESET_TYPES, setPresetTypes] = useState([]);
  const [OPTIONS_LABELS, setOptionsLabels] = useState([]);
  const [TEXTAREA_LABELS, setTextAreaLabels] = useState([]);
  const [PRESET_OPTIONS, setPresetOptions] = useState([]);
  const [SYSTEM_PROMPT_ID, setSystemPromptId] = useState(0);
  const [PLACEHOLDER, setPlaceholder] = useState([]);
  const [LAYOUT_PROMPT, setLayoutPrompt] = useState('');

  const [gptRating, setGptRating] = useState({});
  const [clovaRating, setClovaRating] = useState({});
  const [geminiProRating, setGeminiProRating] = useState({});
  const [geminiFlashRating, setGeminiFlashRating] = useState({});


  const getRatingDict = {
    'GPT-4o': gptRating,
    'ClovaAI': clovaRating,
    'Gemini-1.5-Pro': geminiProRating,
    'Gemini-1.5-Flash': geminiFlashRating
  }
  const setRatingDict = {
    'GPT-4o': setGptRating,
    'ClovaAI': setClovaRating,
    'Gemini-1.5-Pro': setGeminiProRating,
    'Gemini-1.5-Flash': setGeminiFlashRating
  }

  const [userName, setUserName] = useState('');

  const get_copylist = (model_name='all') => {
    return fetch('https://proto-api.pudam.me/copylist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('jwt')}`,
      },
      body: JSON.stringify({
        model_name: model_name
      })
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data)

      for (const model_name of ['GPT-4o',
                              'ClovaAI',
                              'Gemini-1.5-Pro',
                              'Gemini-1.5-Flash']){
          if (data.copy_dict) {
            console.log(data.copy_dict[modelMapper[model_name]])
            setResDict[model_name](data.copy_dict[modelMapper[model_name]].reverse());
            setRatingDict[model_name](data.rating_dict[modelMapper[model_name]]);
          }
      }
    })
    .catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {

    fetch('https://proto-api.pudam.me/valid_check', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('jwt')}`,
      },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      if (data.status == 200) {
        console.log('valid')
        console.log('valid_check', data)
        setUserName(data.username);
      } else {
        console.log('invalid')
        navigate('/');
      }
    })
    .catch((error) => {
      console.log(error)
      navigate('/');
    })





    fetch('https://proto-api.pudam.me/get_system_prompt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('jwt')}`,
      },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      if (data.status == 200) {
        console.log('valid')
        const prompt = data.prompt
        console.log({prompt})
        setSystemPromptId(data.sp_id);
        setCopyTypes(prompt.COPY_TYPES);
        setTextInputPrefix(prompt.TEXT_INPUT_PREFIX);
        setPresetTypes(prompt.PRESET_TYPES);
        setOptionsLabels(prompt.optionsLabels);
        setTextAreaLabels(prompt.textAreaLabels);
        setPresetOptions(prompt.presetOptions);
        setPlaceholder(prompt.placeholder);
        // setTextAreas(prompt.placeholder);
        setLayoutPrompt(prompt.layout);
      } else {
        console.log('invalid')
        navigate('/');
      }
    })
    .catch((error) => {
      console.log(error)
      navigate('/');
    })

    get_copylist().then(() => {
      for (const model_name of ['GPT-4o',
                                'ClovaAI',
                                'Gemini-1.5-Pro',
                                'Gemini-1.5-Flash']){
        setPageDict[model_name](1);
      }
    });

  }, []);

  
  const [activeTab, setActiveTab] = useState(0);
  const [checked, setChecked] = React.useState([false, false, false, false, false, false, false]);
  const [presetValue, setPresetValue] = useState('');
  const [imageValues, setImageValues] = useState([0]);
  const [textAreas, setTextAreas] = useState(Array(6).fill(''));
  const [gptValue, setGptValue] = useState([])
  const [clovaValue, setClovaValue] = useState([])
  const [geminiProValue, setGeminiProValue] = useState([])
  const [geminiFlashValue, setGeminiFlashValue] = useState([])

  const [gptPage, setGptPage] = useState(1)
  const [clovaPage, setClovaPage] = useState(1)
  const [geminiProPage, setGeminiProPage] = useState(1)
  const [geminiFlashPage, setGeminiFlashPage] = useState(1)


  const [sliderValue, setSliderValue] = useState(1.0);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGPT, setIsLoadingGPT] = useState({0:0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0});
  const [isLoadingGeminiPro, setIsLoadingGeminiPro] = useState({0:0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0});
  const [isLoadingGeminiFlash, setIsLoadingGeminiFlash] = useState({0:0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0});
  const [isLoadingClova, setIsLoadingClova] = useState({0:0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0});


  const { enqueueSnackbar } = useSnackbar();


  const StyledButton = styled(Button)(({ theme, isSelected, isGood }) => ({
    borderRadius: '20px',
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    fontWeight: 'bold',
    boxShadow: isSelected ? '0 4px 10px rgba(0, 0, 0, 0.15)' : 'none',
    backgroundColor: isSelected
      ? isGood
        ? '#1976d2'
        : '#f44336'
      : theme.palette.grey[200],
    color: isSelected ? 'white' : theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: isGood ? '#1976d2' : '#d32f2f',
      color: 'white',
      transform: 'translateY(-2px)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    },
  }));

  const RatingButton = (props) => {
    const [rating, setRating] = useState(props.rating);

    const handleRatingChange = (newRating) => {


      handleClickVariant('success', `${props.model_name} 모델에 ${newRating} 피드백이 학습됩니다`);

      setRating(newRating);

      fetch('https://proto-api.pudam.me/rating', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.sessionStorage.getItem('jwt')}`,
        },
        body: JSON.stringify({
          rating: newRating,
          model_name: props.model_name,
          copy_id: props.object.id,
        }),
      })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data.status == 200) {
          console.log('valid')
        } else {
          console.log('invalid')
        }
        get_copylist();
      })
      .catch((error) => {
        console.log(error)
      })

    };

    return (
      <Stack spacing={2} alignItems="center">
        <Typography variant="h6" gutterBottom>
          이 카피가 어떤가요?
        </Typography>
        <Typography variant='body' gutterBottom>
          피드백은 개인화 AI모델에 즉시 학습되어 이후 생성되는 카피에 영향을 줍니다.
        </Typography>
        <Stack direction="row" spacing={2}>
          <StyledButton
            variant="contained"
            startIcon={<ThumbDownRoundedIcon />}
            onClick={() => handleRatingChange('bad')}
            isSelected={rating === 'bad'}
            isGood={false}
          >
            Bad
          </StyledButton>
          <StyledButton
            variant="contained"
            startIcon={<ThumbUpRoundedIcon />}
            onClick={() => handleRatingChange('good')}
            isSelected={rating === 'good'}
            isGood={true}
          >
            Good
          </StyledButton>
        </Stack>
      </Stack>
    );
  };





  const handleClick = (msg) => {
    enqueueSnackbar(msg);
  };

  const handleClickVariant = (variant, msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  const handleCheckChange = (event) => {
    console.log(event.target.value)
    console.log(event.target.checked)
    console.log(checked)
    let newChecked = checked
    newChecked[event.target.value] = event.target.checked
    console.log(newChecked)
    setChecked(newChecked)
    // setRadioValue(event.target.value);
  };
  const handlePresetChange = (event) => {
    setPresetValue(event.target.value);
  };

  const handleTextAreaChange = (index) => (event) => {
    const newValues = [...textAreas];
    newValues[index] = event.target.value;
    setTextAreas(newValues);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const resetLoadingDict = {
    'GPT-4o': () => setIsLoadingGPT({0:0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0}),
    'ClovaAI': () => setIsLoadingClova({0:0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0}),
    'Gemini-1.5-Pro': () => setIsLoadingGeminiPro({0:0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0}),
    'Gemini-1.5-Flash': () => setIsLoadingGeminiFlash({0:0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0})
  }

  const setLoadingDict = {
    'GPT-4o': (indices, value) => {
      console.log('set GPT-ro')
      console.log({indices})
      console.log({value})
      let newLoadingDict = {...isLoadingGPT}
      console.log({newLoadingDict})
      for (const idx of indices) {
        newLoadingDict[idx] = value
      }
      console.log({newLoadingDict})
      setIsLoadingGPT(newLoadingDict)
      return newLoadingDict
    },
    'ClovaAI': (indices, value) => {
      let newLoadingDict = {...isLoadingClova}
      for (const idx of indices) {
        newLoadingDict[idx] = value
      }
      setIsLoadingClova(newLoadingDict)
    },
    'Gemini-1.5-Pro': (indices,value) => {
      let newLoadingDict = {...isLoadingGeminiPro}
      for (const idx of indices) {
        newLoadingDict[idx] = value
      }
      setIsLoadingGeminiPro(newLoadingDict)
    },
    'Gemini-1.5-Flash': (indices,value) => {
      let newLoadingDict = {...isLoadingGeminiFlash}
      for (const idx of indices) {
        newLoadingDict[idx] = value
      }
      setIsLoadingGeminiFlash(newLoadingDict)
    }
  }

  const rawLoadingDict = {
    'GPT-4o': isLoadingGPT,
    'ClovaAI': isLoadingClova,
    'Gemini-1.5-Pro': isLoadingGeminiPro,
    'Gemini-1.5-Flash': isLoadingGeminiFlash
  }


  const sumValues = obj => Object.values(obj).reduce((a, b) => a + b, 0);
  const getLoadingDict = {
    'GPT-4o': () => {
      const r = sumValues(isLoadingGPT)
      console.log('GPT-4o-sumValues', r)
      return (r > 0)
    },
    'ClovaAI': () => sumValues(isLoadingClova)> 0,
    'Gemini-1.5-Pro': () => sumValues(isLoadingGeminiPro)> 0,
    'Gemini-1.5-Flash': () => sumValues(isLoadingGeminiFlash) > 0
  }


  const setResDict = {
    'GPT-4o': setGptValue,
    'ClovaAI': setClovaValue,
    'Gemini-1.5-Pro': setGeminiProValue,
    'Gemini-1.5-Flash': setGeminiFlashValue
  }

  const getResDict = {
    'GPT-4o': gptValue,
    'ClovaAI': clovaValue,
    'Gemini-1.5-Pro': geminiProValue,
    'Gemini-1.5-Flash': geminiFlashValue
  }

  const setPageDict = {
    'GPT-4o': setGptPage,
    'ClovaAI': setClovaPage,
    'Gemini-1.5-Pro': setGeminiProPage,
    'Gemini-1.5-Flash': setGeminiFlashPage
  }

  const getPageDict = {
    'GPT-4o': gptPage,
    'ClovaAI': clovaPage,
    'Gemini-1.5-Pro': geminiProPage,
    'Gemini-1.5-Flash': geminiFlashPage
  }



  const handleSubmit = (modelName) => {
    if (checked.every((value) => value === false) || !presetValue.trim()) {
      alert("소재분류와 카피종류는 비워둘 수 없습니다.");
      return;
    }
    if (modelName) {

      const presetIndex = parseInt(presetValue)
      const copyTypeIndices = checked.reduce((types, isChecked, index) => {
        if (isChecked) {
          types.push(index);
        }
        return types;
      }, []);
      let loadingDict = setLoadingDict[modelName](copyTypeIndices, 1)
      const textAreasList = textAreas
      const temperature = sliderValue

      let model_name = modelName
     

      const promises = [];
      setResDict[modelName](
        [{output_json:JSON.stringify('loading')}, ...getResDict[modelName]]
      );

      for (const copyTypeIndex of copyTypeIndices) {
        handleClick(`${modelName} ${OPTIONS_LABELS[copyTypeIndex]} 생성 요청`);
        const promise = axios.post('https://proto-api.pudam.me/generate_copy', {
          user_input: {
            model_name,
            presetIndex,
            copyTypeIndex,
            textAreasList,
            temperature
          },
          system_prompt_id: SYSTEM_PROMPT_ID,
          base64_images: imageValues,
        }, {
          headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.sessionStorage.getItem('jwt')}`,
          }
        })
          .then((response) => {
        const data = response.data;
        if (data.status === 200) {
          console.log({isLoadingGPT})
          setLoadingDict[modelName]([copyTypeIndex], 0)
          handleClickVariant('success', `${modelName} ${OPTIONS_LABELS[copyTypeIndex]} Copy 생성 완료되었습니다.`);
          setResDict[modelName]([data['response'], ...getResDict[modelName]]);
          setPageDict[modelName](1);
          get_copylist()
        } else {
          if (data['response'] && data['response'].output_json) {
            handleClickVariant('error', `${modelName} Copy 생성 실패. 다시 시도해주세요 ` + JSON.parse(data['response'].output_json));
          }
          // setResDict[model_name](data['response']);
        }
          })
          .catch((error) => {
            console.log(error);
            handleClickVariant('error', `${modelName} Copy 생성 실패`);
            setResDict[model_name]([error.message, ...getResDict[model_name]]);
            console.log([model_name, error]);
          })
          .finally(() => {
        // handleFetchComplete(0);
          });

        promises.push(promise);
      }

      Promise.all(promises)
        .then(() => {
          console.log('All axios requests completed successfully');
          resetLoadingDict[modelName]();
          get_copylist();
        })
        .catch((error) => {
          // resetLoadingDict[modelName]();
          console.log('Error occurred in one or more axios requests');
          console.log(error);
        });
    } else {
      for (let model_name of ['GPT-4o', 'ClovaAI', 'Gemini-1.5-Pro', 'Gemini-1.5-Flash']) {
        console.log(model_name)
        handleSubmit(model_name)
      }
    }
    
  };


  const removeImage = (index) => {
    return () => {
      let _selectedImages = [...selectedImages];
      let _imageValues = [...imageValues];
      _selectedImages.splice(index, 1);
      _imageValues.splice(index, 1);
      setSelectedImages(_selectedImages);
      setImageValues(_imageValues);
    }
  }


  const handleImageSelect = (event) => {
    console.log(event.target.files)
    let _selectedImages = [];
    let _imageValues = [];

    Promise.all(Array.from(event.target.files).map((file, index) => {
      console.log(file);
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          _selectedImages.push(reader.result);
          _imageValues.push(base64String);
          resolve();
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    })).then(() => {
      setSelectedImages([...selectedImages, ..._selectedImages]);
      setImageValues([...imageValues, ..._imageValues]);
    }).catch((error) => {
      console.error(error);
    });


  };


  const [selectedImages, setSelectedImages] = useState([]);


  const DemoPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(0.5),
    paddingTop:0,
    marginTop:0,
    fontSize: '0.8em', 
    whitespace:'pre', 
    wordBreak:'break',
    ...theme.typography.body2,
  }));
  


const handlePageChange = (event, value, model_name) => {
  setPageDict[model_name](value);
  get_copylist();
};


const Sequential =(params)  =>   {
  const page = getPageDict[params.model_name];
  console.log('sequential', params)

  // console.log(getResDict[params.model_name][page-1] && JSON.parse(getResDict[params.model_name][page-1].output_json) === 'loading')
  console.log(getResDict[params.model_name][page-1])
  return (
    <Stack direction='column' spacing={2}  sx={{ display: 'flex', justifyContent: 'center'}}>
      <Pagination  color="primary"  count={params.res.filter(item => typeof(item) !== 'string' ).length}  page={page} onChange={ (e, v) => handlePageChange(e, v, params.model_name)}
      boundaryCount={1}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop:'10px',


      }} />

      <DemoPaper display={getResDict[params.model_name] && ((params.res[page-1] && params.res[page-1].output_json ) || getLoadingDict[params.model_name]())} variant='elevation' elevation={3} key={page} fullWidth={true} sx={{marginTop:0, padding:1, paddingTop:0}} >
        {Object.entries(rawLoadingDict[params.model_name]).filter(([_, value]) => value === 1).map((_, index) => (
          <TypographyDemo key={index} loading={true} />
        ))}
        {getResDict[params.model_name][page-1] && JSON.parse(getResDict[params.model_name][page-1].output_json) !== 'loading' && 
          <ReactMarkdown>
            { params.res[page-1] &&params.res[page-1].output_json && JSON.parse(params.res[page-1].output_json).replaceAll('\n', '\n\n')}
          </ReactMarkdown>
        }
        { !getLoadingDict[params.model_name]()  && params.res[page-1] &&params.res[page-1].output_json && <RatingButton object={params.res[page-1]} model_name={params.model_name} rating={params.res && params.res[page-1].rating}/> }
      </DemoPaper> 
    </Stack>
);
}



const variants = [
  'h1',
  'h3',
  'body1',
  'body1',
  'caption',
  'h3',
  'body1',
  'body1',
  'caption',
  'h3',
  'body1',
  'body1',
  'caption',
] 

function TypographyDemo(props) {
  const { loading = false } = props;

  return (
    <div>
      {variants.map((variant) => (
        <Typography component="div" key={variant} variant={variant}>
          {loading ? <Skeleton /> : variant}
        </Typography>
      ))}
    </div>
  );
}


  const onLogout = () => {
    window.sessionStorage.removeItem('jwt');
    navigate('/');
  }


  return (    
    <>
    <Header username={userName} onLogout={onLogout} />
    <Container maxWidth='xl' >
      <Box sx={{flexGrow: 1}}>
      <Grid container spacing={2}>
        <Grid item xs={7} md={6} lg={5} xl={4}>
          <FormControl component="fieldset">
            <RadioBtn presetValue={presetValue} presetOptions={PRESET_OPTIONS} handlePresetChange={handlePresetChange} />
            {textAreas.map((text, index) => (
              <TextField
                key={index}
                label={`${TEXTAREA_LABELS && TEXTAREA_LABELS[index]}`}
                multiline
                rows={2}
                placeholder={'예시: ' + PLACEHOLDER[index]}
                variant="outlined"
                value={text}
                onChange={handleTextAreaChange(index)}
                margin="normal"
                fullWidth
              />
            ))}
            <Typography variant="h6" gutterBottom>Temperature: 카피의 창의성 정도</Typography>
            <Alert severity="warning">창의성이 높은 것이 항상 도움되지는 않습니다(부정확성증가)</Alert>
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              step={0.1}
              min={0}
              marks
              max={1.5}
              valueLabelDisplay="auto"
            />
            <Typography variant='h6' gutterBottom>카피종류</Typography>

            <FormGroup  onChange={handleCheckChange}>
              {OPTIONS_LABELS && OPTIONS_LABELS.map((label, value) => (
                <FormControlLabel key={value} value={value} control={<Checkbox onChange={handleCheckChange} />} label={label} />
              ))}
            </FormGroup>

            {selectedImages.length!=0 && (

              <div>
                <Typography variant='h6' gutterBottom>레퍼런스 이미지(제품, 브랜드) :</Typography>

              

              <ImageList
                variant="quilted"
                cols={3}
              //   rowHeight={121}
              >
              {
              selectedImages.map((selectedImage, index) =>  
                <ImageListItem key={index} cols={1} rows={1}  clickable>
                  <StyledIconButton onClick={removeImage(index)}>
                  <img src={selectedImage} alt="Selected" 
                    style={{ maxWidth: '100%', marginTop: '10px' 
                    }} 
                    loading="lazy"
                  />
                  </StyledIconButton>
                </ImageListItem>
                )
              }
              </ImageList>

              </div>
            )
            }

            <Button variant="contained" component="label">
              Select Image
              <input multiple type="file" accept="image/*" hidden onChange={handleImageSelect} />
            </Button>
          </FormControl>
        </Grid>

        <Grid item xs={12-7} md={12-6} lg={12-5} xl={12-4}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSubmit(0)}
            style={{ marginTop: '20px' }}
            disabled={isLoading}
            fullWidth={true}
          >
            모든 모델 카피 생성
          </Button>

          <Box sx={{ borderBottom: 1, borderColor: 'divider',  }}>

          <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
            {['GPT-4o', 'ClovaAI', 'Gemini-1.5-Pro', 'Gemini-1.5-Flash'].map((model_name) => (
              <Tab 
              // sx={{color: getLoadingDict[model_name] ? '#FF6600' : ''}}
              sx={{color: getLoadingDict[model_name]() ? '#FFA500' : ''}}
               label={getLoadingDict[model_name]() ? `${model_name}(busy)` :  `${model_name}`} {...model_name} />
            ))}
          </Tabs>
          </Box>

          {['GPT-4o', 'ClovaAI', 'Gemini-1.5-Pro', 'Gemini-1.5-Flash'].map((model_name, i) => (
            <CustomTabPanel value={activeTab} index={i}>
              <Card variant='outlined' sx={{ maxWidth: '100%'}}>
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <LoadingButton
                  variant='outlined'
                    loadingPosition="start" 
                    fullWidth={true}
                    startIcon={<DrawOutlinedIcon />}
                    // startIcon={' '}
                    onClick={() => handleSubmit(model_name)}
                    style={{ marginTop: '20px' }}
                    loading={getLoadingDict[model_name]()}
                    // disabled={isLoading}
                  >
                    {model_name} 모델 카피 생성

                  </LoadingButton>
                  </Stack>
                  <Typography color="text.secondary" variant="body2">
                  <Sequential res={getResDict[model_name]}  model_name={model_name}/>

                  </Typography>
                </Box>
              </Card>

            </CustomTabPanel>
          ))}
        </Grid>
      </Grid>
      </Box>
    </Container>
    </>
  );
}

export default function IntegrationNotistack() {
  return (
    <Copywriter />
  );
}
