import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginForm from './LoginForm';
import { clientId } from './Config';
import styled from 'styled-components';
import logo from './logo.svg';
import madupLogo from './madup_logo.svg';

const LoginPage = () => {
    return (
        <GoogleOAuthProvider clientId={clientId}>
            <PageContainer>
                <LoginCard>
                    <Title>MadCopywriter</Title>
                    <Subtitle>Log in to your account</Subtitle>
                    <center>

                    <LoginForm style={{width:'100%'}}/>
                        </center>
                    <LogoContainer>
                        {/* <br></br>
                        <br></br>
                        <img style={{ width:'100px'}}src={madupLogo} alt="Logo" /> */}
                        <br></br>
                        <br></br>
                        <img style={{ width:'100px'}} src={logo} alt="Logo" />
                    </LogoContainer>
                </LoginCard>
            </PageContainer>
        </GoogleOAuthProvider>
    );
};

const PageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
`;

const LoginCard = styled.div`
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 100%;
    max-width: 400px;
`;

const LogoContainer = styled.div`
    text-align: center;
    margin-bottom: 2rem;
`;

const Logo = styled.img`
    max-width: 150px;
`;

const Title = styled.h1`
    color: #1e3c72;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    text-align: center;
`;

const Subtitle = styled.p`
    color: #666;
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: center;
`;

export default LoginPage;