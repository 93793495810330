import React from 'react';
import { Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';

function RadioBtn({ presetValue, presetOptions, handlePresetChange }) {
    return (
        <>
            <Typography gutterBottom variant="h6">소재분류</Typography>
            <RadioGroup value={presetValue} onChange={handlePresetChange}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                {presetOptions.map((label, value) => (
                    <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                ))}
                </div>
            </RadioGroup>
        </>
    );
}

export default RadioBtn;