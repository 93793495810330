import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Avatar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
  boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
}));

const UserInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 2),
  transition: 'all 0.3s',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.1)',
  color: theme.palette.primary.contrastText,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}));

const Header = ({ username='', onLogout }) => {
  return (
    <StyledAppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
          MadCopywriter
        </Typography>
        <UserInfo>
          <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 'medium', color: 'white' }}>
          </Typography>
            {username}님 환영합니다!
        </UserInfo>
        <LogoutButton 
          variant="outlined"
          onClick={onLogout}
          startIcon={<LogoutIcon />}
        >
          Logout
        </LogoutButton>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;