import React, { useEffect, useState } from 'react';
import Main from './Copywriter';
import LoginPage from './LoginPage';

const Index = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        // Check if the user is logged in using Google OAuth
        // You can implement your own logic here

        // For example, you can use the Google Sign-In API
        // and check if the user is signed in

        // If the user is logged in, set isLoggedIn to true
        // Otherwise, set isLoggedIn to false
        // You can replace the following line with your own logic
        setIsLoggedIn(false);
    }, []);

    return isLoggedIn ? <Main /> : <LoginPage />;
};

export default Index;